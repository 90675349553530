import { IRefunds, IRefundsList } from '@components/transactions/refunds/models/refunds.interface';
import * as moment from 'moment';
import { DATE_FULL_EUROPEEN_FORMAT } from '@constants/date';
import { IMarketplace } from '@interfaces/marketplace.interface';
import { IButtonLinks } from '@components/generic/buttons/button-links.interface';
import { SessionHelper } from '@helpers';

export class RefundsListModel implements IRefundsList {
  '@id': string;
  id: string;
  createdAt: string;
  walisoftOrderId: string;
  orderId: string;
  customerName: string;
  orderDate: string;
  marketplace: string;
  transactionId: string;
  orderAmount: string;
  operationId: string;
  refundAmount: string;
  isSageExported: boolean;
  zendeskTicketId: string;
  providerStatus: string;
  providerTransactionStatus?: string;
  buttonLinks: IButtonLinks[];
  status: string;
  comment: string;
  countryCode: string;
  locale: string;
  iban?: string;
  bic?: string;

  constructor(refund: IRefunds) {
    this['@id'] = refund['@id'];
    this.id = refund.id;
    this.createdAt = refund.createdAt ? moment(refund.createdAt).format(DATE_FULL_EUROPEEN_FORMAT) : '';
    this.walisoftOrderId = refund.order.id;
    this.orderId = refund.order.humanReadableOrderId;
    this.customerName = `${refund.order.billingAddress.firstName || ''} ${refund.order.billingAddress.lastName}`;
    this.orderDate = refund.order.date ? moment(refund.order.date).format(DATE_FULL_EUROPEEN_FORMAT) : '';
    this.marketplace = this.formatMarketplaces(refund.order.marketplace);
    this.transactionId = refund.order.transactionId;
    this.orderAmount = refund.order.totalPrice.toFixed(2);
    this.operationId = refund.order.operationId;
    this.refundAmount = refund.amount.toFixed(2);
    this.isSageExported = refund.order.sageExported;
    this.zendeskTicketId = refund.zendeskTicketId;
    this.providerStatus = refund.providerStatus;
    this.providerTransactionStatus = refund.providerTransactionStatus;
    this.buttonLinks = this.formatButtonLinks(refund.ribPath);
    this.status = refund.status;
    this.comment = refund.comment;
    this.countryCode = refund.order.billingAddress.countryCode;
    this.locale = refund.order.locale;
    this.iban = refund.iban;
    this.bic = refund.bic;
  }

  public formatMarketplaces(code: string): string {
    const marketplace = SessionHelper.getAllMarketplaces()
      .find((m: IMarketplace) => m.code === code);

    return marketplace ? marketplace.commercialName : code;
  }

  public formatButtonLinks(url: string): IButtonLinks[] {
    return [
        {
          url,
          label: 'PAGE.REFUNDS.LIST.TABLE.BUTTONS.DOWNLOAD_RIB',
        }
      ];
  }
}
