import { Inject, Injectable } from '@angular/core';
import { AbstractService } from '@services/abstract.service';
import { ICategories } from '@components/categories/models';
import { SessionHelper } from '@helpers';
import { WebsiteCategoriesResource } from '@components/cms/website_categorie.resource';
import { takeUntil } from 'rxjs/operators';
import { IProduct } from '@components/product/interfaces';

@Injectable()
export class RteEmbedPagesService extends AbstractService {
  private categories: ICategories[];
  private products: IProduct[];
  private attributes: any;
  private attributeValues: any;
  private selectedAttributes: any;

  constructor(
    @Inject('TranslationService') private translate: ng.translate.ITranslateService,
    private websiteCategoriesResource: WebsiteCategoriesResource
  ) {
    super();
  }

  getCmsRteEmbedPages(categories: ICategories[], products: IProduct[]): any {
    this.categories = categories;
    this.products = products;
    return (editor: any) => {
      editor.ui.registry.addButton('addContent', {
        text: this.translate.instant('PAGE.CMS.FORM.RTE.PLUGIN.INSERT_CONTENT'),
        onAction: () => this.onAction(editor, {})
      });
    };
  }

  public onAction(editor: any, categoryTabOptions: any) {
    editor.windowManager.open(this.getRedial(editor, categoryTabOptions));
  }

  private getRedial(editor: any, categoryTabOptions: any) {
    return {
      title: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.TITLE'),
      body: {
        type: 'tabpanel',
        tabs: [
          this.categoryTabConfig(categoryTabOptions),
          this.productTabConfig(),
          {
            name: 'form',
            title: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.CRM'),
            items: [{
              type: 'input',
              name: 'form',
              label: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.ENTER_CRM'),
              flex: true
            }],
          },
        ],
      },
      onChange: (api: any, details: any) => {
        switch (details.name) {
          case 'category':
            this.dialogOnChangeCategory(api, editor);
            break;
          case 'filter':
            this.dialogOnChangeFilter(api, editor);
            break;
          case 'filter_value':
            this.dialogOnChangeFilterValue(api, editor);
            break;
        }
      },
      onAction: (api: any, details: any) => {
        switch (details.name) {
          case 'new_filter':
            this.dialogOnActionNewFilter(api, editor);
            break;
          case 'add_filter':
            this.dialogOnActionAddFilter(api, editor);
            break;
        }
        if (details.name.indexOf('remove_filter_') === 0) {
          this.dialogOnActionRemoveFilter(api, editor, +details.name.replace('remove_filter_', ''));
        }
      },
      onSubmit: (api: any) => {
        api.getData().form ? this.insertForm(api, editor) : api.getData().product ? this.insertProduct(api, editor) : this.insertCategory(api, editor);
        api.close();
      },
      onTabChange: (api: any, details: any) => {
        api.setData({ [details.oldTabName]: '' });
      },
      buttons: [
        {
          text: 'Close',
          type: 'cancel',
          onclick: 'close'
        },
        {
          text: 'Insert',
          type: 'submit',
          primary: true,
          enabled: false
        }
      ]
    };
  }

  private dialogOnChangeCategory(api: any, editor: any) {
    const data = JSON.parse(api.getData().category);
    this.attributes = null;
    this.attributeValues = null;
    this.selectedAttributes = [];
    const apiDatas = api.getData();
    api.redial(this.getRedial(editor, data.type === 'shelf' ? { 'newFilter': true } : {}));
    api.setData(apiDatas);
  }

  private dialogOnChangeFilter(api: any, editor: any) {
    const apiDatas = api.getData();
    apiDatas.filter_value = '';
    api.setData(apiDatas);
    const datas: any = apiDatas.filter !== '' ? JSON.parse(apiDatas.filter) : {};
    if (datas.hasOwnProperty('children')) {
      const attributesValues = datas.children;
      attributesValues.unshift({
        text: '',
        value: ''
      });
      this.attributeValues = attributesValues;
      api.redial(this.getRedial(editor, {
        attributes: this.attributes,
        attributesValues: this.attributeValues
      }));
      api.setData(apiDatas);
    } else {
      api.redial(this.getRedial(editor, { attributes: this.attributes }));
      api.setData(apiDatas);
    }
  }

  private dialogOnChangeFilterValue(api: any, editor: any) {
    const data = api.getData().filter_value ? JSON.parse(api.getData().filter_value) : false;
    const apiDatas = api.getData();
    api.redial(this.getRedial(editor, data ? {
      attributes: this.attributes,
      attributesValues: this.attributeValues,
      canAddFilter: true
    } : {
      attributes: this.attributes,
      attributesValues: this.attributeValues
    }));
    api.setData(apiDatas);
  }

  private dialogOnActionNewFilter(api: any, editor: any) {
    const data = JSON.parse(api.getData().category);
    if (data.type === 'shelf') {
      if (!this.attributes) {
        this.websiteCategoriesResource.cGet(
          { slug: data.slug, isUniverse: 0, locale: SessionHelper.getLocale() },
          { dontUseModel: true }
        )
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response: any) => {
              const attributes = response.data.attributes
                // Transform to easy use
                .map((attribute: any) => ({
                  text: attribute.name,
                  value: JSON.stringify({
                    slug: attribute.slug,
                    name: attribute.name,
                    children: attribute.families.map((family: any) => ({
                      text: family.value,
                      value: JSON.stringify({
                        slug: family.slug,
                        name: family.value,
                      })
                    }))
                  })
                }));
              // Add empty value for no set
              attributes.unshift({
                text: '',
                value: ''
              });
              this.attributes = attributes;
              this.addAttributeFilter(api, editor);

              return true;
            },
          );
      } else {
        this.addAttributeFilter(api, editor);
      }
    }
  }

  private dialogOnActionAddFilter(api: any, editor: any) {
    const apiDatas = api.getData(),
      filter = JSON.parse(apiDatas.filter),
      filter_value = JSON.parse(apiDatas.filter_value);
    const selectedFilter = JSON.stringify({ filter, filter_value });
    if (!this.selectedAttributes || !this.selectedAttributes.map) {
      this.selectedAttributes = [selectedFilter];
    } else if (this.selectedAttributes.indexOf(selectedFilter) === -1) {
      this.selectedAttributes.push(selectedFilter);
    }
    api.redial(this.getRedial(editor, { 'newFilter': true }));
    api.setData(apiDatas);
  }

  private dialogOnActionRemoveFilter(api: any, editor: any, index: number) {
    const apiDatas = api.getData(),
      removeAttribute = this.selectedAttributes[index];
    this.selectedAttributes = this.selectedAttributes.filter((selectedAttribute: any) => selectedAttribute !== removeAttribute);
    if (apiDatas.filter) {
      api.redial(this.getRedial(editor, {
        attributes: this.attributes,
        attributesValues: apiDatas.filter !== '' ? this.attributeValues : null,
        canAddFilter: !!apiDatas.filter_value
      }));
    } else {
      api.redial(this.getRedial(editor, { 'newFilter': true }));
    }
    api.setData(apiDatas);
  }

  private addAttributeFilter(api: any, editor: any) {
    const datas = api.getData();
    api.redial(this.getRedial(editor, { attributes: this.attributes }));
    api.setData(datas);
  }

  private categoryTabConfig(categoryTabOptions: any) {
    const items = [];

    items.push({
      type: 'selectbox',
      name: 'category',
      label: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.SELECT_CATEGORY'),
      items: this.categories,
      flex: true
    });

    if (this.selectedAttributes && !!this.selectedAttributes.map) {
      for (let index = 0; index < this.selectedAttributes.length; index++) {
        const selectedAttribute = JSON.parse(this.selectedAttributes[index]),
          filter = selectedAttribute.filter,
          filterValue = selectedAttribute.filter_value;
        items.push({
          type: 'grid',
          columns: 2,
          items: [
            {
              type: 'label',
              label: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.FILTER_TOOL.FILTER') + ' ' + (index + 1),
              items: [{
                type: 'htmlpanel', // component type
                html: `<b>${filter.name}</b>: <i>${filterValue.name}</i>`
              }]
            },
            {
              type: 'button',
              name: 'remove_filter_' + index,
              text: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.FILTER_TOOL.REMOVE_FILTER'),
              primary: true,
            }
          ]
        });
      }
    }

    if (categoryTabOptions.newFilter) {
      items.push({
        type: 'button',
        name: 'new_filter',
        text: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.FILTER_TOOL.NEW_FILTER'),
        primary: true,
      });
    }

    if (categoryTabOptions.attributes) {
      items.push({
        type: 'grid',
        columns: 3,
        items: [
          {
            type: 'selectbox',
            name: 'filter',
            label: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.FILTER_TOOL.FILTER'),
            items: categoryTabOptions.attributes,
            flex: true
          },
          {
            type: 'selectbox',
            name: 'filter_value',
            label: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.FILTER_TOOL.FILTER_VALUE'),
            disabled: !categoryTabOptions.attributesValues,
            items: categoryTabOptions.attributesValues || [],
            flex: true
          },
          {
            type: 'label',
            label: '&nbsp;',
            items: [{
              type: 'button',
              name: 'add_filter',
              text: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.FILTER_TOOL.ADD_FILTER'),
              disabled: !categoryTabOptions.canAddFilter,
              primary: !!categoryTabOptions.canAddFilter,
            }]
          }
        ]
      });
    }

    return {
      name: 'category',
      title: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.CATEGORY'),
      items: items,
    };
  }

  private productTabConfig() {
    const items = [];

    items.push({
      type: 'input',
      name: 'product',
      label: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.SELECT_PRODUCT'),
      // items: this.products,
      flex: true
    });

    items.push({
      type: 'input',
      name: 'attribute',
      label: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.ATTRIBUTE'),
      flex: true
    });

    items.push({
      type: 'checkbox',
      name: 'super_product',
      label: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.SUPER_PRODUCT'),
      flex: true
    });

    return {
      name: 'product',
      title: this.translate.instant('PAGE.CMS.FORM.RTE.DIALOG.PRODUCT'),
      items: items,
    };
  }

  private insertCategory(api: any, editor: any) {
    const datas = api.getData();
    const category = JSON.parse(datas.category);
    const dataAttr = [
      `data-category="${category.id}"`
    ];
    let content = '';
    if (this.selectedAttributes && !!this.selectedAttributes.map && this.selectedAttributes.length > 0) {
      const dataAttrAttributePattern = [];
      for (let index = 0; index < this.selectedAttributes.length; index++) {
        const selectedAttribute = JSON.parse(this.selectedAttributes[index]),
          filter = selectedAttribute.filter,
          filterValue = selectedAttribute.filter_value;
        content += `<p><b>${filter.name}</b>: <i>${filterValue.name}</i></p>`;
        dataAttrAttributePattern.push(filter.slug, filterValue.slug);
      }
      dataAttr.push('data-attribute-pattern="' + dataAttrAttributePattern.join('/') + '"');
      this.selectedAttributes = null;
    }
    content += '<br>';
    editor.insertContent(`<div ` + dataAttr.join(' ') + ` class=${category.type}>${content}</div>\n`);
  }

  private insertForm(api: any, editor: any) {
    const form = api.getData().form;
    editor.insertContent(`<div data-form="cmsRegister" data-crm=${form} class="form"></div>\n`);
  }

  private insertProduct(api: any, editor: any) {
    const product = api.getData().product;
    const superProduct = api.getData().super_product;
    const attribute = api.getData().attribute;

    editor.insertContent(`<span data-sku="${product}" ${superProduct ? 'data-sku-parent' : ''} data-${attribute}>&nbsp;</span>\n`);
  }
}
